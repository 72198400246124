<template>
  <div>
    <section class="s-testing">
      <div class="container">
        <div class="testing-container">
          <div class="hero-title">Ваш результат</div>
          <div class="hero-text">{{ text }}</div>
          <div class="chart">
            <RadarChart
              ref="chart"
              :max="10"
              :height="500"
              :chartData="radarData"
              :options="radarOptions"
            />
          </div>

          <div v-if="description" class="description">
            {{ description }}
          </div>

          <div class="actions">
            <button @click="resetResult" class="btn btn-o reset-btn">
              Пройти снова
            </button>

            <button
              @click="openTestModal = true"
              class="btn btn-accent reset-btn"
            >
              Скачать расширенную версию отчета
            </button>
          </div>
          <div class="link" v-show="testPdf">
            Ссылка на скачивание:
            <button
              ref="linkBtn"
              @click="
                windowContainer.open(`${apiUrl}/pdf/get/${testPdf}`, '_blank')
              "
            >
              {{ `${apiUrl}/pdf/get/${testPdf}` }}
            </button>
          </div>
        </div>
      </div>
    </section>
    <product-slider
      :products="products"
      title="Вам рекомендовано"
    ></product-slider>
  </div>
  <pop-up-form
    title="Получить отчет"
    type="TestModal"
    companyField
    v-if="openTestModal"
    @closeModal="openTestModal = false"
    @sendTestForm="sendTestForm"
  ></pop-up-form>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed, onBeforeMount } from "@vue/runtime-core";
import ProductSlider from "../../components/sliders/product-slider/ProductSlider.vue";
import { useRouter } from "vue-router";
import { RadarChart } from "vue-chart-3";
import { useStore } from "vuex";
import PopUpForm from "../../components/pop-up-form/PopUpForm.vue";
export default {
  components: { ProductSlider, RadarChart, PopUpForm },
  name: "testing-result-page",
  setup() {
    const router = useRouter(),
      store = useStore();
    let radarData = ref({}),
      radarOptions = ref({
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          r: {
            pointLabels: {
              backdropPadding: 0,
              padding: 0,
            },
            suggestedMax: 10,
            suggestedMin: 0,
            ticks: {
              display: false,
              stepSize: 2,
            },
          },
        },
        // // Генерируем картинку когда анимация закончится, чтобы дождаться пока чарт отрендерится
        // animation: {
        //   onComplete: () => loadChartImage(),
        // },
      }),
      sum = 0,
      description = ref(""),
      text = ref(""),
      // Нужна для скриншота чарта
      chart = ref(null),
      openTestModal = ref(false);

    const products = computed(() => store.state.testProducts),
      testPdf = computed(() => store.state.testPdf);

    const apiUrl = process.env.VUE_APP_API_URL;
    const linkBtn = ref();
    const windowContainer = window;

    onBeforeMount(async () => {
      const finalScore = JSON.parse(localStorage.getItem("finalScore"));

      if (finalScore) {
        sum = Object.values(finalScore).reduce((a, b) => a + b);
        store.dispatch("getTestProductsByScore", sum);
        if (sum <= 18) {
          text.value =
            "Уровень ЭИ-зрелости вашей компании достаточно низкий.\n Срочно бегите к директору! Время действовать!";
          description.value =
            "В вашей компании сложные времена. Слишком много внутренних проблем, слишком мало времени и ресурсов на их разрешение. Эмоции не просто не помогают, а мешают вашей компании развиваться. Как результат – текучка, конфликты, стресс, простои. Комплексный подход к развитию ЭИ сотрудников и руководителей, а также создание ЭИ-среды в организации позволит решить основные проблемы.";
        } else if (sum <= 30) {
          text.value =
            "Уровень ЭИ-зрелости вашей компании достаточно низкий. Внутренняя атмосфера довольно тяжелая и многие сотрудники чувствуют себя некомфортно.";
          description.value =
            "В вашей компании часто эмоции становятся не союзниками, а врагами. Из-за этого появляется много проблемных зон, требующих внимания и ресурсов на их разрешение. Времени, сил и финансов становится меньше, а проблемы имеют тенденцию накапливаться. Комплексный подход к развитию ЭИ сотрудников и руководителей, а также создание ЭИ-среды в организации позволит решить первостепенные задачи.";
        } else if (sum <= 41) {
          text.value =
            "ЭИ-зрелость вашей компании не так высока как хотелось бы. Есть некоторые проблемы, и есть риск их увеличения.";
          description.value =
            "В вашей компании все средне: есть проблемные ситуации, но и есть ресурсы для их разрешения. Скорее всего, часть полезной эмоциональной информации просто упускается из виду. Эмоции не до конца  используются как ресурс. Наверняка вы уже заметили, что трудности имеют тенденцию появляться все чаще. Вам поможет системный подход к развитию ЭИ сотрудников и руководящего звена.";
        } else {
          text.value = "Вы – пример ЭИ-зрелой компании!";
          description.value =
            "Поздравляем! В вашей компании нет проблем с основными направлениями работы (при условии что проблемы не замалчиваются). Судя по всему ваша команда интуитивно понимает механизм работы эмоционального интеллекта. Благодаря этому сотрудники почти не доводят ситуацию до критического состояния. Поддерживать сложившийся баланс и вывести его на осмысленный уровень поможет формирование ЭИ-среды в компании.";
        }

        radarData.value = {
          labels: Object.keys(finalScore).map((key) => key.split(" ")),
          datasets: [
            {
              data: Object.values(finalScore),
              backgroundColor: "#01af0870",
              borderColor: "#01af08",
              pointBackgroundColor: "#01af08",
            },
          ],
        };
      } else {
        router.push("/testing");
      }
    });

    const sendTestForm = (email) => {
      store
        .dispatch("sendTestDataToGeneratePdf", {
          sum,
          scores: radarData.value?.datasets[0]?.data,
          image: chart.value.chartInstance.toBase64Image(),
          email,
        })
        .then(() => {
          linkBtn.value.click();
        });
    };
    const resetResult = () => {
      localStorage.removeItem("finalScore");
      router.push("/testing");
    };

    return {
      sum,
      description,
      text,
      radarData,
      radarOptions,
      products,
      resetResult,
      chart,
      testPdf,
      openTestModal,
      sendTestForm,
      apiUrl,
      linkBtn,
      windowContainer,
    };
  },
};
</script>

<style lang="scss" scoped>
.s-testing {
  padding: 150px 0 60px;

  .hero-title {
    font-size: 32px;
    margin-bottom: 30px;
    color: var(--accent);
    text-align: center;
  }

  .hero-text {
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    max-width: 80%;
    margin: auto;
  }

  .description {
    text-align: center;
  }

  .testing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reset-btn {
    margin-top: 30px;
    margin-left: 20px;
  }

  .actions {
    display: flex;
  }

  .link {
    margin-top: 20px;
    border: 2px solid var(--accent);
    padding: 12px 18px 14px;
    border-radius: 24px;
    font-size: 1.2rem;
    margin-left: 20px;
    word-break: break-word;
    font-weight: 500;

    button {
      color: var(--accent);
      padding: 0;
      border: none;
      font: inherit;
      background-color: transparent;
      cursor: pointer;
    }
  }

  @media (max-width: 767px) {
    padding: 85px 0 50px;

    .container {
      padding: 0 15px;
    }

    .chart {
      margin: -10px;
      & > div {
        margin: 25px 0;
      }
    }

    .actions {
      flex-direction: column;
    }
  }

  @media (max-width: 650px) {
    .hero-text {
      max-width: 100%;
    }
  }
}

.results-wrapper {
  display: flex;
  justify-content: space-between;
}
.results-block {
  flex: 0 1 58%;

  &__result {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 10px;

    span {
      margin-right: 3px;
      font-weight: 400;
    }
  }
}
.results-form {
  flex: 0 1 39%;
}

@media (max-width: 996px) {
  .results-wrapper {
    flex-direction: column;
  }

  .results-block,
  .results-form {
    flex: 1;
  }

  .results-block {
    margin-bottom: 10px;
  }
}
</style>